import stylesForm from "@components/Form/Form.module.scss";
import styles from "./ModalExtendRental.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { useCurrentMission } from "src/Stores/CurrentMission";
import DatePicker from "@components/DatePicker/DatePicker";
import HourPicker from "@components/HourPicker/HourPicker";
import { useMemo, useState } from "react";
import { Oval } from "react-loader-spinner";
import { format } from "date-fns";
import { VRentalRecapType } from "src/Stores/CurrentMissionTypes/MissionContextType";
import { useTranslation } from "react-i18next";

const isSameDay = (date1: Date | null, date2: Date | null) => {
  if (date1 === null || date2 === null) return false;
  return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export default function ModalExtendRental({
  onRequestClose,
  rental,
  containerProps: { className, ...containerProps } = {},
}: {
  onRequestClose?: () => any;
  rental: VRentalRecapType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { t } = useTranslation();
  const { callAction } = useCurrentMission();

  const initialEndDate = useMemo(() => {
    const currentDatePlusOneHour = new Date();
    currentDatePlusOneHour.setHours(currentDatePlusOneHour.getHours() + 1);

    const rentalEndDate = new Date(rental.endDateTime);

    if (rentalEndDate < currentDatePlusOneHour) {
      return currentDatePlusOneHour;
    }
    return rentalEndDate;
  }, [rental.endDateTime]);

  const dateStart = new Date(rental.startDateTime);
  const dateEnd = new Date(rental.endDateTime);

  const [extendActionLoading, setExtendActionLoading] = useState(false);

  const [endDate, setEndDate] = useState<Date | null>(initialEndDate);
  const [endHour, setEndHour] = useState<number | null>(initialEndDate.getHours());
  const [endMinutes, setEndMinutes] = useState<number | null>(initialEndDate.getMinutes());

  const isSameDayAndHour = isSameDay(initialEndDate, endDate) && initialEndDate.getHours() === endHour;

  const extendRental = () => {
    if (!rental.extendAction || !callAction) return;
    if (endDate === null || endHour === null) return;

    const dateCloned = new Date(endDate);
    dateCloned.setHours(endHour);
    dateCloned.setMinutes(0);
    dateCloned.setSeconds(0);
    const until =
      dateCloned.getFullYear() +
      "-" +
      `${dateCloned.getMonth() + 1}`.padStart(2, "0") +
      "-" +
      `${dateCloned.getDate()}`.padStart(2, "0") +
      "T" +
      `${dateCloned.getHours()}`.padStart(2, "0") +
      ":00:00";

    setExtendActionLoading(true);
    callAction(rental.extendAction, {
      until,
    })
      .then(() => {
        onRequestClose && onRequestClose();
      })
      .finally(() => {
        setExtendActionLoading(false);
      });
  };

  return (
    <Modal disableModalScroll onRequestClose={onRequestClose} title={t("extendLocationModalTitle")}>
      <div
        className={[stylesForm.form, styles.modalContainer, className].join(" ")}
        {...containerProps}
      >
        <div className={styles.currentRentalInfos}>
          {t("currentRentalInfo", {
            numberOfDays: rental.numberOfDays,
            plural: rental.numberOfDays > 1 ? "s" : "",
            start: format(dateStart, "dd/MM HH:mm"),
            end: format(dateEnd, "dd/MM HH:mm"),
          })}
        </div>

        <div className={styles.extendRentalTitle}>
          {t("extendRentalTitle")}
        </div>

        <div className={stylesForm.formRow}>
          <div className={stylesForm.formCol}>
            <div className={[stylesForm.formCol].join(" ")}>
              <DatePicker
                testid="date-picker-startdate"
                disabled={extendActionLoading}
                date={endDate}
                onChange={(date) => {
                  setEndDate(date);
                  if (
                    isSameDay(date, initialEndDate) &&
                    endHour !== null &&
                    endHour < initialEndDate.getHours()
                  ) {
                    setEndHour(initialEndDate.getHours());
                  }
                }}
                minDate={initialEndDate}
              />
              <span className={styles.dateHourSeparator}>à</span>
              <HourPicker
                testid="hour-picker-startdate"
                disabled={extendActionLoading}
                hour={endHour}
                minutes={endMinutes}
                minHour={
                  isSameDay(initialEndDate, endDate) ? initialEndDate.getHours() : 0
                }
                onChange={({ hour, minutes }) => {
                  setEndHour(hour);
                  setEndMinutes(minutes);
                }}
              />
            </div>
          </div>
        </div>

        <Button
          containerProps={{
            className: stylesForm.buttonSubmit,
            disabled:
              extendActionLoading ||
              isSameDayAndHour ||
              endDate === null ||
              endHour === null,
          }}
          onClick={extendRental}
        >
          {extendActionLoading ? (
            <Oval
              height={20}
              width={20}
              color="white"
              secondaryColor="white"
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          ) : (
            t("confirmExtend")
          )}
        </Button>

        <Button
          label={t("cancel")}
          type="secondary"
          containerProps={{
            className: stylesForm.buttonCancel,
            disabled: extendActionLoading,
          }}
          onClick={() => onRequestClose && onRequestClose()}
        />
      </div>
    </Modal>
  );
}