import { create } from "zustand";
import { persist } from "zustand/middleware";

interface NotificationsStore {
  notificationsOpened: boolean;
  setNotificationsOpened: (value: boolean) => void;
}

const useNotificationsStore = create<NotificationsStore>()(
  persist(
    (set) => ({
      notificationsOpened: false,
      setNotificationsOpened: (value) => set({ notificationsOpened: value }),
    }),
    {
      name: "notifications-storage",
    }
  )
);

export default useNotificationsStore;
